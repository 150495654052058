<template lang="pug">
#signature-capture.step-component
  h2 Your Signature

  canvas#signature-pad(ref="pad")

  .signature-footer
    VerificationButton.done(
      :disabled="disableButtons",
      @click.native="saveSignature",
      :state="buttonState",
      text="Done"
    )
    button.clear(:disabled="disableButtons || signatureSaving", @click="clearSignature") Clear
</template>

<script>
import SignaturePad from 'signature_pad'
import { transparentPng } from '@/utils/index'

export default {
  props: {},

  data() {
    return {
      signaturePad: null,
      canClear: false,
      signatureAdded: false,
      signatureSaving: false,
      signatureData: transparentPng,
      onResizeHandler: null,
      buttonState: 'init',
    }
  },

  beforeCreate() {},

  mounted() {
    this.signaturePad = new SignaturePad(this.$refs.pad, {
      minWidth: 1,
      maxWidth: 2,
      penColor: '#000000',
      backgroundColor: '#FFFFFF',
    })

    this.onResizeHandler = this.resizeCanvas
    window.addEventListener('resize', this.onResizeHandler, false)

    setTimeout(() => {
      this.resizeCanvas()
    })
  },

  beforeDestroy() {
    if (this.onResizeHandler) {
      window.removeEventListener('resize', this.onResizeHandler, false)
    }
  },

  computed: {
    disableButtons,
  },

  watch: {
    '$breakpoint.orientation': function (newOrientation, oldOrientation) {
      if (oldOrientation && newOrientation != oldOrientation) {
        this.resizeCanvas()
      }
    },
  },

  methods: {
    clearSignature,
    resizeCanvas,
    saveSignature,
  },

  components: {
    VerificationButton: require('@/components/VerificationButton').default,
  },
}

/* Computed ---------------------------------------------------- */
function disableButtons() {
  return this.signaturePad?.isEmpty()
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function clearSignature() {
  this.signaturePad?.clear()
}

function resizeCanvas() {
  const canvas = this.$refs.pad
  const ctx = canvas.getContext('2d')
  const data = this.signaturePad.toData()
  const ratio = Math.max(window.devicePixelRatio || 1, 1)
  const parentElement = document.getElementById('signature-capture')

  if (this.$breakpoint.isPhone) {
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = 600
  } else {
    canvas.width = parentElement.offsetWidth * ratio
    canvas.height = 400
  }

  ctx.scale(ratio, ratio)

  this.clearSignature()
  this.signatureData = transparentPng
  this.signaturePad.fromData(data)
}

function saveSignature() {
  this.signatureSaving = true
  this.buttonState = 'try'

  // Generate consent PDF with signature appended
  this.$refs.pad.toBlob(blob => {
    this.$store.dispatch('user/uploadSignature', {
      file: blob,
      fileName: `signature-${Date.now()}.png`,
    })
  }, 'image/png')

  this.buttonState = 'success'
  setTimeout(() => {
    this.$emit('complete')
  }, 1000)
}
</script>
